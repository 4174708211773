import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../config/axios';
import auth from '../../../config/auth';
import { areArraysEqual } from '../../global/utils/helpers';
import {
  getHealthDeclaration,
  updateHealthDeclaration,
} from '../../captains-form/components/health/slices/healthSlice.tsx';
import { removeNullUndefined } from '../../global/utils/cleanEmptyData';
import { getRelatedPortByUnlocode } from '../../global/utils/ports';

export const FIRST_BERTH_STAY_ID = 1;

const initialState = {
  portCall: {
    uid: '',
    general: {
      masterName: '',
      masterSurname: '',
      masterPhoneNumber: '',
      masterTreatment: 'masterTreatment',
      port: null,
      isOpenToMaster: null,
      unlocode: null,
      isVesselCarryingCargo: null,
    },
    healthDeclaration: {
      hasValidSanitationCertificateCarriedOnBoard: false,
      placeOfIssuance: '',
      issuingDate: null,
      expiringDate: null,
      reinspectionRequired: false,
      hasShipVisitedInfectedArea: false,
      infectedAreaPort: '',
      infectedAreaDate: null,
      portsVisitedWithinWHO: [],
      hasAnyPersonDiedOtherwiseThanAccident: false,
      howManyDeadPeople: 0,
      hasAnyCaseOfInfectiousDisease: false,
      hasTheTotalNumberOfIllPassengersGreaterThanNormal: false,
      howManyIllPeople: 0,
      isThereAnyIllPeopleOnBoard: false,
      areYouAwareOfAnyConditionWhichMayLeadToSpreadOfDisease: false,
      wasAMedicalPractitionerConsulted: false,
      stateParticulars: [],
      hasSanitaryMeasuresBeenAppliedOnBoard: false,
      isThereASickAnimalOnBoard: false,
      sanityMeasures: [],
    },
    portCallData: {
      data: {
        eta: null,
        etd: null,
        lastPort: {},
        nextPort: {},
        purposeOfCall: '',
        arrivalDraft: null,
        departureDraft: null,
        isAbleToInternationalShipping: false,
        isAuthorizedForShortseaOrCabotage: false,
        suppliesFreshWater: false,
        suppliesBunkering: false,
        suppliesIce: false,
        suppliesElectricity: false,
      },
    },
    vesselBunkeringDetailsData: {
      uid: null,
      vesselUid: null,
      rateReceptionPerHour: null,
      maxPressure: null,
      diameterConnection: null,
      canReceiveDifferentProductsSimultaneously: false,
      canReceiveDifferentGradesSimultaneously: false,
      isDeckCraneAvailable: false,
      freeBoardOnArrival: null,
      lengthBetweenPerpendiculars: null,
      bollardsLocationFromStern: null,
      bunkerManifold: {
        sizeOfConnectionGradeAndType: null,
        situation: {
          manifoldPosition1: [],
          manifoldPosition2: [],
          distanceFromStern: null,
          heightAboveWaterline: null,
          distanceFromPortSide: null,
        },
      },
      isVesselFittedWithScrubber: false,
      haveCertifiedPersonnelToAccessBarge: false,
    },
    vesselData: {
      imo: '',
      shipName: '',
      shipType: '',
      grossTonnage: '',
      deadweight: '',
      length: '',
      flag: '',
      callsign: '',
      mmsi: '',
      buildDate: '',
      beam: '',
    },
    inspections: {
      data: {
        // dateLastExpandedInspection: "",
        // portLastExpandedInspection: "",
        isSubjectedToExpandedInspection: false,
        conditionOfCargoTanks: 'Full',
        conditionOfBallastTanks: 'Full',
        // plannedStatutorySurveys: ""
      },
      carriedOutPortSearchResults: [],
      ui: {
        carriedOutPortLoading: false,
      },
    },
    berth: {
      berthStays: [
        {
          berthId: FIRST_BERTH_STAY_ID,
          arrivalDraft: null,
          departureDraft: null,
          purposeOfCall: '',
          suppliesFreshWater: false,
          suppliesBunkering: false,
          suppliesIce: false,
          suppliesElectricity: false,
        },
      ],
    },
    crew: {
      masterData: {
        givenName: '',
        familyName: '',
        middleName: '',
        nationality: '',
        phoneNumber: '',
      },
      arrival: [],
      departure: [],
      crewEffects: [],
      crewList: [],
      crewListDocumentLink: '',
      app: {
        arrivalCrewChanged: false,
        departureCrewChanged: false,
      },
    },
    passengers: {
      arrival: [],
      departure: [],
      app: {
        arrivalPassengersChanged: false,
        departurePassengersChanged: false,
      },
    },
    stowaways: {
      arrivalStowaways: 0,
      departureStowaways: 0,
    },
    dangerousGoods: {
      dangerousGoodsData: null,
      dangerousGoodsList: [],
      dangerousGoodsSearchResults: [],
      ui: {
        dangerousGoodsSearchResultsLoading: false,
        showDropdown: false,
      },
    },
    cargo: {
      arrivalCargo: {
        typeOfCargo: '',
        volume: 0,
        description: '',
      },
      departureCargo: {
        typeOfCargo: '',
        volume: 0,
        description: '',
      },
      declaredCargo: [],
    },
    solas: {
      data: {
        contactDetails24h: '',
        CSOName: '',

        hasValidISSC: true,
        certificateExpiryDate: '',
        certificateNumber: '',
        certificateIssuer: '',
        hasApprovedSSP: true,
        shipSecurityLevel: 'LEVEL_1',
        sspNoReason: '',

        wereApprovedProceduresFollowedInShipToShipOperations: false,
        securityMeasures: [],

        // last10PortCalls
        hasAdditionalSecurityMeasures: false,
        // additionalSecuretyMeasures
        // hasValidISMC
        // ISMCCertificateIssueDate
        // ISMCCertificateExpiryDate
      },
      relations: {},
    },
    generalDeclaration: {},
    missingFields: true,
  },
};

export const getPortCall = createAsyncThunk('portCall/getPortCall', async (uiid) => {
  let endpoint = `portcalls/${uiid}`;
  const headers = auth.getHeaders();

  const response = await axiosInstance.get(endpoint, { headers });

  return response.data;
});

export const updatePortCall = createAsyncThunk('portCall/updatePortCall', async (portCallData, { getState }) => {
  const {
    portCall: {
      portCall: { uid },
    },
  } = getState();

  const headers = auth.getHeaders();
  let endpoint = `portcalls/${uid}`;

  let body = {};

  if (portCallData.general) {
    body = {
      ...portCallData.general,
    };
  }

  const response = await axiosInstance.put(endpoint, { ...portCallData }, { headers });

  return portCallData;
});

export const updatePortCallStatus = createAsyncThunk(
  'portCall/updatePortCallStatus',
  async ({ uid, newStatus }, { getState }) => {
    const {
      portCall: {
        portCall: {
          general: { status },
        },
      },
    } = getState();
    try {
      const headers = auth.getHeaders();
      const endpoint = `portcalls/${uid}/status/${newStatus}`;
      await axiosInstance.put(endpoint, {}, { headers });
      return newStatus;
    } catch (e) {
      return status;
    }
  }
);

export const getPortCallData = createAsyncThunk('portCall/getPortCallData', async (uid, { getState }) => {
  const headers = auth.getHeaders();

  let endpoint = `portcalls/${uid}/portcall-data`;
  const response = await axiosInstance.get(endpoint, { headers });

  return response.data.data;
});

export const updatePortCallData = createAsyncThunk(
  'portCall/updatePortCallData',
  async ({ uid, data: portCallPayload }, { getState }) => {
    const {
      portCall: {
        portCall: {
          portCallData: {},
        },
      },
    } = getState();
    const headers = auth.getHeaders();
    let endpoint = `portcalls/${uid}/portcall-data`;

    await axiosInstance.put(
      endpoint,
      {
        portcallData: {
          ...portCallPayload,
        },
      },
      { headers }
    );

    return portCallPayload;
  }
);

export const updateMissingFields = createAsyncThunk('portCall/missingfields', async ({ isThereMissingFields }) => {
  return isThereMissingFields;
});

export const getVesselBunkeringDetails = createAsyncThunk(
  'portCall/getVesselBunkeringDetails',
  async (uid, { getState }) => {
    const headers = auth.getHeaders();
    let response = await axiosInstance.get(`/portcalls/${uid}/vessel-bunkering-details`, {
      headers,
    });

    return response.data.data;
  }
);

export const updateVesselBunkeringDetails = createAsyncThunk(
  'portCall/updateVesselBunkeringDetails',
  async ({ data, uid }) => {
    const headers = auth.getHeaders();
    await axiosInstance.put(
      `/portcalls/${uid}/vessel-bunkering-details`,
      {
        ...data,
      },
      {
        headers,
      }
    );

    return data;
  }
);

export const getVessel = createAsyncThunk('portCall/getVessel', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/vessel`, {
    headers,
  });

  return response.data.data;
});

export const updateVessel = createAsyncThunk('portCall/updateVessel', async ({ data, uid }) => {
  const headers = auth.getHeaders();
  await axiosInstance.put(
    `/portcalls/${uid}/vessel`,
    {
      ...data,
    },
    {
      headers,
    }
  );

  return data;
});

export const getInspections = createAsyncThunk('portCall/getInspections', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/mou`, {
    headers,
  });

  return response.data;
});

export const updateInspections = createAsyncThunk('portCall/updateInspections', async ({ data, uid }, { getState }) => {
  const headers = auth.getHeaders();

  let response = await axiosInstance.put(
    `/portcalls/${uid}/mou`,
    {
      ...data,
    },
    {
      headers,
    }
  );

  return response.data.data;
});

export const getBerth = createAsyncThunk('portCall/getBerth', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/berth`, {
    headers,
  });
  return response.data.data;
});

export const updateBerth = createAsyncThunk('portCall/updateBerth', async ({ uid, data }, { getState }) => {
  const {
    portCall: {
      portCall: { berth: berthStays },
    },
  } = getState();

  const previousFirstBerthStay = berthStays.berthStays[0];
  const currentFirstBerthStay = {
    ...previousFirstBerthStay,
    ...data.berthStay,
  };
  const otherBerthStays = berthStays.berthStays?.slice(1) || [];
  const newBerthStays = [currentFirstBerthStay, ...otherBerthStays];
  const berthsToApi = {
    berthStays: newBerthStays.map((berth) => {
      const newBerth = {
        ...berth,
        eta: berth?.eta ? removeNullUndefined(berth?.eta) : null,
        etc: berth?.etc ? removeNullUndefined(berth?.etc) : null,
        ets: berth?.ets ? removeNullUndefined(berth?.ets) : null,
        etd: berth?.etd ? removeNullUndefined(berth?.etd) : null,
      };
      return removeNullUndefined(newBerth);
    }),
  };
  await axiosInstance.put(`/portcalls/${uid}/berth`, berthsToApi, {
    headers: auth.getHeaders(),
  });

  return berthsToApi;
});

export const getCrew = createAsyncThunk('portCall/getCrew', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/crew`, {
    headers,
  });

  return response.data.data;
});

export const getCrewListDocumentLink = createAsyncThunk(
  'portCall/getCrewListDocumentLink',
  async (uid, { getState }) => {
    const headers = auth.getHeaders();
    let response = await axiosInstance.get(`/portcalls/${uid}/document-links/crew-list`, {
      headers,
    });
    return response.data.data;
  }
);

export const importCrewList = createAsyncThunk('portCall/importCrewList', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.post(
    `/portcalls/${uid}/crew/import`,
    {},
    {
      headers,
    }
  );
  return response.data;
});

export const updateCrew = createAsyncThunk('portCall/updateCrew', async ({ uid, data }, { getState }) => {
  const {
    portCall: {
      portCall: {
        crew: { crewList, crewEffects },
      },
    },
  } = getState();

  const headers = auth.getHeaders();

  await axiosInstance.put(
    `/portcalls/${uid}/crew`,
    {
      crewList,
      crewEffects,
      ...data,
    },
    {
      headers,
    }
  );

  return data;
});

export const getPassengers = createAsyncThunk('portCall/getPassengers', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/passengers`, {
    headers,
  });

  return response.data;
});

export const updatePassengers = createAsyncThunk('portCall/updatePassengers', async ({ uid, data }, { getState }) => {
  const {
    portCall: {
      portCall: {
        passengers: { arrival, departure },
      },
    },
  } = getState();
  const headers = auth.getHeaders();

  await axiosInstance.put(
    `/portcalls/${uid}/passengers`,
    {
      arrival,
      departure,
      ...data,
    },
    {
      headers,
    }
  );

  return data;
});

export const searchPortOfEmbarkation = createAsyncThunk('portCall/searchPortOfEmbarkation', async (searchData) => {
  let endpoint = `/ports/`;
  if (searchData.unlocode) {
    endpoint += `?unlocode=${searchData.unlocode}`;
  } else if (searchData.portName) {
    endpoint += `?name=${searchData.portName}`;
  }

  const headers = auth.getHeaders();
  const response = await axiosInstance.get(endpoint, {
    headers,
  });

  // mapping into format that dropdown expects
  return response.data.data.map((port) => {
    return {
      value: port.unlocode,
      unlocode: port.unlocode,
      countryCode: port.countryCode,
      label: port.name + ', ' + port.countryCode,
      name: port.name,
    };
  });
});

export const searchPortOfDisembarkation = createAsyncThunk(
  'portCall/searchPortOfDisembarkation',
  async (searchData) => {
    let endpoint = `/ports/`;
    if (searchData.unlocode) {
      endpoint += `?unlocode=${searchData.unlocode}`;
    } else if (searchData.portName) {
      endpoint += `?name=${searchData.portName}`;
    }

    const headers = auth.getHeaders();
    const response = await axiosInstance.get(endpoint, {
      headers,
    });

    // mapping into format that dropdown expects
    return response.data.data.map((port) => {
      return {
        value: port.unlocode,
        countryCode: port.countryCode,
        label: port.name + ', ' + port.countryCode,
      };
    });
  }
);

export const searchDangerousGoods = createAsyncThunk('portCall/searchDangerousGoods', async ({ un }, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/dangerous-goods/?un=${un}`, {
    headers,
  });

  // mapping into format that dropdown expects
  return response.data.data.map((dangerousGood) => {
    return {
      value: dangerousGood.name,
      label: `UN: ${dangerousGood.un}, ${dangerousGood.name}, packingGroup: ${dangerousGood.packingGroup || '-'}`,
      data: dangerousGood,
    };
  });
});

export const getDangerousGoodsData = createAsyncThunk('portCall/getDangerousGoodsData', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/dangerous-goods-data`, {
    headers,
  });

  return response.data.data;
});

export const updateDangerousGoodsData = createAsyncThunk(
  'portCall/updateDangerousGoodsData',
  async ({ uid, dangerousGoodsData }, { getState }) => {
    const headers = auth.getHeaders();

    await axiosInstance.put(
      `/portcalls/${uid}/dangerous-goods-data`,
      {
        dangerousGoodsData: {
          ...dangerousGoodsData,
        },
      },
      {
        headers,
      }
    );

    return dangerousGoodsData;
  }
);

export const getDangerousGoods = createAsyncThunk('portCall/getDangerousGoods', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/dangerous-goods`, {
    headers,
  });

  return response.data.data;
});

export const updateDangerousGoods = createAsyncThunk(
  'portCall/updateDangerousGoods',
  async (dangerousGoodsData, { getState }) => {
    const {
      portCall: {
        portCall: { uid },
      },
    } = getState();
    const headers = auth.getHeaders();

    await axiosInstance.put(
      `/portcalls/${uid}/dangerous-goods`,
      {
        ...dangerousGoodsData,
      },
      {
        headers,
      }
    );

    return dangerousGoodsData;
  }
);

export const getCargo = createAsyncThunk('portCall/getCargo', async (uid, { getState }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/cargo`, {
    headers,
  });

  return response.data.data;
});

export const updateCargo = createAsyncThunk('portCall/updateCargo', async (cargoData, { getState }) => {
  const {
    portCall: {
      portCall: { uid },
    },
  } = getState();
  const headers = auth.getHeaders();

  await axiosInstance.put(
    `/portcalls/${uid}/cargo`,
    {
      ...cargoData,
    },
    {
      headers,
    }
  );
  return cargoData;
});

export const getIsps = createAsyncThunk('portCall/getIsps', async (uid) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/isps`, {
    headers,
  });

  return response.data;
});

export const updateIsps = createAsyncThunk('portCall/updateIsps', async ({ ispsData, uid }) => {
  const headers = auth.getHeaders();
  await axiosInstance.put(
    `/portcalls/${uid}/isps`,
    {
      ...ispsData,
    },
    {
      headers,
    }
  );

  return ispsData;
});

export const getGeneralDeclaration = createAsyncThunk('portCall/getGeneralDeclaration', async (uid) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/portcalls/${uid}/general-declaration`, {
    headers,
  });

  return {
    generalDeclarationAccepted: response.data.data.generalDeclarationAccepted,
    generalDeclarationRemarks: response.data.data.generalDeclarationRemarks,
  };
});

export const updateMasterSignature = createAsyncThunk('portCall/updateMasterSignature', async ({ image, uid }) => {
  const headers = auth.getHeaders();
  await axiosInstance.put(
    `/portcalls/${uid}/master-signature`,
    {
      image: image,
    },
    {
      headers,
    }
  );

  return image;
});

export const updateGeneralDeclaration = createAsyncThunk(
  'portCall/updateGeneralDeclaration',
  async ({ generalDeclaration, uid }) => {
    const headers = auth.getHeaders();
    await axiosInstance.put(
      `/portcalls/${uid}/general-declaration`,
      {
        generalDeclarationAccepted: generalDeclaration.generalDeclarationAccepted,
        generalDeclarationRemarks: generalDeclaration.generalDeclarationRemarks,
      },
      {
        headers,
      }
    );

    return generalDeclaration;
  }
);

function withPortDropdowns(relations, passengers) {
  return passengers.map((passenger) => {
    if (passenger.portOfEmbarkation) {
      const relatedPort = getRelatedPortByUnlocode({
        ports: relations?.ports || [],
        unlocode: passenger.portOfEmbarkation,
      });
      if (relatedPort) {
        passenger.portOfEmbarkationDropdown = relatedPort;
      }
    }
    if (passenger.portOfDisembarkation) {
      const relatedPort = getRelatedPortByUnlocode({
        ports: relations?.ports || [],
        unlocode: passenger.portOfDisembarkation,
      });
      if (relatedPort) {
        passenger.portOfDisembarkationDropdown = relatedPort;
      }
    }

    return passenger;
  });
}

export const portCallSlice = createSlice({
  name: 'portCall',
  initialState,
  reducers: {
    setDepartureCrewChanged: (state, action) => {
      const { departureCrewChanged } = action.payload;
      state.portCall.crew.app.departureCrewChanged = departureCrewChanged;
    },
    setDeparturePassengersChanged: (state, action) => {
      const { departurePassengersChanged } = action.payload;
      state.portCall.passengers.app.departurePassengersChanged = departurePassengersChanged;
    },
    showDangerousGoodsModal: (state, action) => {
      state.portCall.dangerousGoods.ui.showDropdown = true;
    },
    closeDangrousGoodsDropdown: (state, action) => {
      state.portCall.dangerousGoods.ui.showDropdown = false;
    },
    setHealthDeclaration: (state, action) => {
      const { healthDeclaration } = action.payload;
      state.portCall.healthDeclaration = healthDeclaration;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHealthDeclaration.fulfilled, (state, action) => {
        state.portCall.healthDeclaration = {
          ...state.portCall.healthDeclaration,
          ...action.payload,
        };
      })
      .addCase(updateHealthDeclaration.fulfilled, (state, action) => {
        const { healthData } = action.payload;
        state.portCall.healthDeclaration = {
          ...state.portCall.healthDeclaration,
          ...healthData,
        };
      })
      .addCase(getPortCall.fulfilled, (state, action) => {
        const {
          data: {
            uid,
            status,
            masterName,
            masterSurname,
            masterNationality,
            masterPhoneNumber,
            masterSignature,
            masterSignatureTimestamp,
            masterTreatment,
            arrivalStowaway,
            departureStowaway,
            instructionsText,
            isOpenToMaster,
            unlocode,
            vesselBunkeringDetailsData,
            vessel,
            isVesselCarryingCargo,
          },
          relations,
        } = action.payload;

        state.portCall = {
          ...state.portCall,
          uid,
          general: {
            status,
            masterName,
            masterSurname,
            masterPhoneNumber,
            masterTreatment,
            masterNationality,
            masterSignature,
            masterSignatureTimestamp,
            instructionsText,
            isOpenToMaster,
            unlocode,
            vesselBunkeringDetailsData,
            vessel,
            isVesselCarryingCargo,
            relations,
          },
          stowaways: {
            arrivalStowaways: arrivalStowaway,
            departureStowaways: departureStowaway,
          },
        };
      })
      .addCase(updatePortCall.fulfilled, (state, action) => {
        const {
          arrivalStowaway,
          departureStowaway,
          masterName,
          masterSurname,
          masterPhoneNumber,
          masterNationality,
          isVesselCarryingCargo,
        } = action.payload;

        if (masterName) {
          state.portCall.general.masterName = masterName;
        }

        if (masterSurname) {
          state.portCall.general.masterSurname = masterSurname;
        }

        if (masterPhoneNumber) {
          state.portCall.general.masterPhoneNumber = masterPhoneNumber;
        }

        if (masterNationality) {
          state.portCall.general.masterNationality = masterNationality;
        }

        state.portCall.general.isVesselCarryingCargo = isVesselCarryingCargo;

        state.portCall.stowaways.arrivalStowaways = arrivalStowaway
          ? arrivalStowaway
          : state.portCall.stowaways.arrivalStowaways;
        state.portCall.stowaways.departureStowaways = departureStowaway
          ? departureStowaway
          : state.portCall.stowaways.departureStowaways;
      })
      .addCase(updatePortCallStatus.fulfilled, (state, action) => {
        state.portCall = {
          ...state.portCall,
          general: {
            ...state.portCall.general,
            status: action.payload,
          },
        };
      })
      .addCase(getPortCallData.pending, (state) => {})
      .addCase(getPortCallData.fulfilled, (state, action) => {
        if (action.payload.portcallData) {
          state.portCall.portCallData.data = action.payload.portcallData;
        }
      })
      .addCase(getPortCallData.rejected, (state, action) => {
        // setGlobalError(action.error)
      })
      .addCase(updatePortCallData.pending, (state) => {})
      .addCase(updatePortCallData.fulfilled, (state, action) => {
        state.portCall.portCallData.data = {
          ...state.portCall.portCallData.data,
          ...action.payload,
        };
      })
      .addCase(updatePortCallData.rejected, (state, action) => {})

      .addCase(getVessel.fulfilled, (state, action) => {
        state.portCall.vesselData = action.payload;
      })
      .addCase(updateVessel.fulfilled, (state, action) => {
        state.portCall.vesselData = action.payload;
      })
      .addCase(getVesselBunkeringDetails.fulfilled, (state, action) => {
        state.portCall.vesselBunkeringDetailsData = action.payload;
      })
      .addCase(updateVesselBunkeringDetails.fulfilled, (state, action) => {
        state.portCall.vesselBunkeringDetailsData = action.payload;
      })
      .addCase(getInspections.fulfilled, (state, action) => {
        const { data, relations } = action.payload;
        state.portCall.inspections.data = data ? data : state.portCall.inspections.data;

        if (data && data.portLastExpandedInspection) {
          const { portLastExpandedInspection } = data;

          relations?.ports?.forEach((port) => {
            if (port.unlocode === portLastExpandedInspection) {
              data.dropdownPortLastExpandedInspection = port;
            }
          });
        }
      })
      .addCase(updateInspections.fulfilled, (state, action) => {})
      .addCase(getBerth.pending, (state) => {})
      .addCase(getBerth.fulfilled, (state, action) => {
        const { berthStays } = action.payload;
        state.portCall.berth = { berthStays };
      })
      .addCase(getBerth.rejected, (state, action) => {})
      .addCase(updateBerth.pending, (state) => {})
      .addCase(updateBerth.fulfilled, (state, action) => {
        state.portCall.berth = action.payload;
      })
      .addCase(updateBerth.rejected, (state, action) => {})
      .addCase(getCrew.pending, (state) => {})
      .addCase(getCrew.fulfilled, (state, action) => {
        const { crewList, crewEffects, arrivalCrewQuantity, departureCrewQuantity } = action.payload;

        state.portCall.crew.crewList = crewList || [];
        state.portCall.crew.crewEffects = crewEffects || [];

        const portUnlocode = state.portCall.general.unlocode;

        const arrivalCrewList = state.portCall.crew.crewList.filter(
          (crewMember) => crewMember.portOfEmbarkation !== portUnlocode
        );
        const departureCrewList = state.portCall.crew.crewList.filter(
          (crewMember) => crewMember.portOfDisembarkation !== portUnlocode
        );

        if (!areArraysEqual(arrivalCrewList, departureCrewList)) {
          state.portCall.crew.app.departureCrewChanged = true;
        }

        state.portCall.crew.arrivalCrewQuantity = arrivalCrewQuantity;
        state.portCall.crew.departureCrewQuantity = departureCrewQuantity;
      })
      .addCase(getCrewListDocumentLink.rejected, (state, action) => {})
      .addCase(getCrewListDocumentLink.pending, (state) => {})
      .addCase(getCrewListDocumentLink.fulfilled, (state, action) => {
        const { crewListDocumentLink } = action.payload;
        state.portCall.crew.crewListDocumentLink = crewListDocumentLink;
      })
      .addCase(getCrew.rejected, (state, action) => {})
      .addCase(updateCrew.pending, (state) => {})
      .addCase(updateCrew.fulfilled, (state, action) => {
        state.portCall.crew = {
          ...state.portCall.crew,
          ...action.payload,
        };
      })
      .addCase(updateCrew.rejected, (state, action) => {})
      .addCase(getPassengers.pending, (state) => {})
      .addCase(getPassengers.fulfilled, (state, action) => {
        const {
          data: { arrival, departure, arrivalPassengerQuantity, departurePassengerQuantity },
          relations,
        } = action.payload;

        state.portCall.passengers.arrival = withPortDropdowns(relations, arrival);
        state.portCall.passengers.departure = withPortDropdowns(relations, departure);

        if (!areArraysEqual(arrival, departure)) {
          state.portCall.passengers.app.departurePassengersChanged = true;
        }

        state.portCall.passengers.arrivalPassengerQuantity = arrivalPassengerQuantity;
        state.portCall.passengers.departurePassengerQuantity = departurePassengerQuantity;
      })
      .addCase(getPassengers.rejected, (state, action) => {})
      .addCase(updatePassengers.pending, (state) => {})
      .addCase(updatePassengers.fulfilled, (state, action) => {
        state.portCall.passengers = {
          ...state.portCall.passengers,
          ...action.payload,
        };
      })
      .addCase(updatePassengers.rejected, (state, action) => {})

      .addCase(searchDangerousGoods.pending, (state) => {
        state.portCall.dangerousGoods.dangerousGoodsSearchResults = [];
      })
      .addCase(searchDangerousGoods.fulfilled, (state, action) => {
        state.portCall.dangerousGoods.dangerousGoodsSearchResults = action.payload;
        state.portCall.dangerousGoods.ui.showDropdown = true;
      })
      .addCase(searchDangerousGoods.rejected, (state, action) => {})
      .addCase(getDangerousGoodsData.pending, (state) => {})
      .addCase(getDangerousGoodsData.fulfilled, (state, action) => {
        state.portCall.dangerousGoods.dangerousGoodsData = action.payload;

        const { dangerousGoodsStatus } = action.payload;
        if (dangerousGoodsStatus) {
          let dangerousGoodsType = [];
          Object.keys(dangerousGoodsStatus).forEach((key) => {
            if (dangerousGoodsStatus[key]) {
              dangerousGoodsType.push(key);
            }
          });

          state.portCall.dangerousGoods.dangerousGoodsData.dangerousGoodsType = dangerousGoodsType;
        }
      })
      .addCase(getDangerousGoodsData.rejected, (state, action) => {})
      .addCase(updateDangerousGoodsData.pending, (state) => {})
      .addCase(updateDangerousGoodsData.fulfilled, (state, action) => {
        state.portCall.dangerousGoods.dangerousGoodsData = action.payload;

        const { dangerousGoodsStatus } = action.payload;
        if (dangerousGoodsStatus) {
          let dangerousGoodsType = [];
          Object.keys(dangerousGoodsStatus).forEach((key) => {
            if (dangerousGoodsStatus[key]) {
              dangerousGoodsType.push(key);
            }
          });

          state.portCall.dangerousGoods.dangerousGoodsData.dangerousGoodsType = dangerousGoodsType;
        }
      })
      .addCase(updateDangerousGoodsData.rejected, (state, action) => {})
      .addCase(getDangerousGoods.pending, (state) => {})
      .addCase(getDangerousGoods.fulfilled, (state, action) => {
        state.portCall.dangerousGoods.dangerousGoodsList = action.payload || [];
      })
      .addCase(getDangerousGoods.rejected, (state, action) => {})
      .addCase(updateDangerousGoods.pending, (state) => {})
      .addCase(updateDangerousGoods.fulfilled, (state, action) => {
        state.portCall.dangerousGoods.dangerousGoodsList = action.payload.dangerousGoods;
      })
      .addCase(updateDangerousGoods.rejected, (state, action) => {})
      .addCase(getCargo.pending, (state) => {})
      .addCase(getCargo.fulfilled, (state, action) => {
        action.payload.declaredCargo = action.payload.declaredCargo || [];

        state.portCall.cargo = action.payload;
      })
      .addCase(getCargo.rejected, (state, action) => {})
      .addCase(updateCargo.pending, (state) => {})
      .addCase(updateCargo.fulfilled, (state, action) => {
        state.portCall.cargo = {
          ...state.portCall.cargo,
          ...action.payload,
        };
      })
      .addCase(updateCargo.rejected, (state, action) => {})
      .addCase(getIsps.pending, (state) => {})
      .addCase(getIsps.fulfilled, (state, action) => {
        const { data, relations } = action.payload;

        state.portCall.solas.relations = relations || {};
        state.portCall.solas.data = data
          ? {
              ...state.portCall.solas.data,
              ...data,
            }
          : state.portCall.solas.data;

        if (data && data.last10PortCalls) {
          data.last10PortCalls.map((portcall) => {
            if (portcall.portName && portcall.portUnLoCode) {
              portcall.portNameDropdown = {
                unlocode: portcall.portUnLoCode,
                name: portcall.portName,
                countryCode: portcall.portCountry,
              };
            }
            return portcall;
          });
        }
      })
      .addCase(getIsps.rejected, (state, action) => {})
      .addCase(updateIsps.pending, (state) => {})
      .addCase(updateIsps.fulfilled, (state, action) => {
        state.portCall.solas.data = {
          ...state.portCall.solas.data,
          ...action.payload,
        };
      })
      .addCase(updateIsps.rejected, (state, action) => {})
      .addCase(getGeneralDeclaration.pending, (state) => {})
      .addCase(getGeneralDeclaration.fulfilled, (state, action) => {
        state.portCall.generalDeclaration = action.payload;
      })
      .addCase(getGeneralDeclaration.rejected, (state, action) => {})
      .addCase(updateGeneralDeclaration.pending, (state) => {})
      .addCase(updateGeneralDeclaration.fulfilled, (state, action) => {
        state.portCall.generalDeclaration = action.payload;
      })
      .addCase(updateGeneralDeclaration.rejected, (state, action) => {})
      .addCase(updateMissingFields.pending, (state, action) => {})
      .addCase(updateMissingFields.fulfilled, (state, action) => {
        state.portCall.missingFields = action.payload;
      })
      .addCase(updateMissingFields.rejected, (state, action) => {});
  },
});

export const {
  setDepartureCrewChanged,
  setDeparturePassengersChanged,
  showDangerousGoodsModal,
  closeDangrousGoodsDropdown,
  addDangerousGood,
  updateDangerousGood,
  deleteDangerousGood,
  deleteAllDangerousGoods,
  setHealthDeclaration,
} = portCallSlice.actions;

export const selectGeneralData = (state) => state.portCall.portCall.general;

export const selectPortCallData = (state) => state.portCall.portCall.portCallData;

export const selectVesselBunkeringDetailsData = (state) => state.portCall.portCall.vesselBunkeringDetailsData;

export const selectVesselData = (state) => state.portCall.portCall.vesselData;

export const selectInspections = (state) => state.portCall.portCall.inspections;

export const selectBerth = (state) => state.portCall.portCall.berth;

export const selectCrew = (state) => state.portCall.portCall.crew;

export const selectPassengers = (state) => state.portCall.portCall.passengers;

export const selectStowaways = (state) => state.portCall.portCall.stowaways;

export const selectDangerousGoods = (state) => state.portCall.portCall.dangerousGoods;

export const selectCargo = (state) => state.portCall.portCall.cargo;

export const selectSolas = (state) => state.portCall.portCall.solas;

export const selectGeneralDeclaration = (state) => state.portCall.portCall.generalDeclaration;

export const selectMissingFields = (state) => state.portCall.portCall.missingFields;

export default portCallSlice.reducer;
